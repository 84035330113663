<template>
  <payment-reconciliation-table
    :loading="loading"
    :columns="TABLE_COLUMNS"
    :items="items"/>
</template>

<script>
import {paymentReconciliations} from '@/services';
import {captureException} from '@sentry/vue';
import PaymentReconciliationTable from './PaymentReconciliationTable.vue';

const TABLE_COLUMNS = [
  'Date',
  'Admin Transaction Value',
  'Admin Transaction Count',
  'Checkout Transaction Value',
  'Checkout Transaction Count',
];

export default {
  name: 'PaymentReconciliationSummary',
  components: {
    PaymentReconciliationTable,
  },
  data() {
    return {
      loading: false,
      items: [],
      TABLE_COLUMNS,
    };
  },
  watch: {
    $route() {
      this.fetchSummary();
    },
  },
  mounted() {
    this.fetchSummary();
  },
  methods: {
    async fetchSummary() {
      this.loading = true;
      try {
        const {start_date, end_date} = this.$route.query;
        if (!start_date || !end_date) {
          return;
        }

        const {items} = await paymentReconciliations.fetch({start_date, end_date}, {path: '/summary'});
        this.items = items;
      }
      catch (error) {
        captureException(error);
      }
      finally {
        this.loading = false;
      }
    },
  },
};
</script>
