<template>
  <div class="container">
    <dimmer>
      <div class="page-header justify-content-center">
        <div class="card mb-0">
          <div class="card-header">
            <div class="card-title">Payment reconciliation</div>
          </div>
          <div class="card-body">
            <payment-reconciliation-filter @onDateSelected="handleDateSelected"/>
          </div>
        </div>
      </div>
    </dimmer>

    <div class="row">
      <div class="col">
        <div class="card">
          <payment-reconciliation-tabs :tab="tab" @tab="setTab">
            <button
              v-if="tab === 'unsuccessful' && can(uiPermissions.PAYMENT_RECONCILIATIONS_EXPORT)"
              class="btn btn-primary"
              title="Export"
              :disabled="!canExport"
              @click="handleExport">
              <i class="fe fe-download-cloud"></i>
            </button>
          </payment-reconciliation-tabs>
          <payment-reconciliation-summary v-if="tab === 'summary'"/>
          <payment-reconciliations v-if="tab === 'successful'"/>
          <payment-reconciliations v-if="tab === 'unsuccessful'"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import download from '@/mixins/download';
import {paymentReconciliations} from '@/services';
import {captureException} from '@sentry/vue';
import PaymentReconciliationFilter from './components/PaymentReconciliationFilter.vue';
import PaymentReconciliationSummary from './components/PaymentReconciliationSummary.vue';
import PaymentReconciliationTabs from './components/PaymentReconciliationTabs.vue';
import PaymentReconciliations from './components/PaymentReconciliations.vue';

export default {
  name: 'PaymentReconciliation',
  components: {
    PaymentReconciliationFilter,
    PaymentReconciliationTabs,
    PaymentReconciliationSummary,
    PaymentReconciliations,
  },
  mixins: [download],
  data() {
    return {
      tab: '',
      loading: false,
    };
  },
  computed: {
    canExport() {
      const {start_date, end_date} = this.$route.query;
      return start_date && end_date && !this.loading;
    },
  },
  mounted() {
    this.setTab(this.$route.query.t || 'summary');
  },
  methods: {
    setTab(t) {
      this.tab = t;
      this.$router.replace({query: {...this.$route.query, t}});
    },
    async handleDateSelected(date) {
      const [startDate, endDate] = date.map(d => d.format('YYYY-MM-DD'));
      this.$router.replace({
        query: {
          ...this.$route.query,
          start_date: startDate,
          end_date: endDate,
        },
      });
    },
    async handleExport() {
      try {
        this.loading = true;
        const {start_date, end_date} = this.$route.query;
        const report = await paymentReconciliations.export({start_date, end_date, success: false});
        this.download(report);
      }
      catch (error) {
        captureException(error);
      }
      finally {
        this.loading = false;
      }
    },
  },
};
</script>
