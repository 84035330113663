<template>
  <ul class="nav nav-tabs w-100 mx-0" role="tablist">
    <template v-for="t in tabs">
      <li
        v-if="can(t.permission)"
        :key="t.value"
        class="nav-item">
        <a
          class="nav-link"
          :data-test="`tab-${t.value}`"
          :class="{ 'active': tab === t.value }"
          @click="setTab(t.value)">{{t.title}}</a>
      </li>
    </template>

    <!-- actions on tab -->
    <li class="d-flex justify-content-end align-items-center flex-1 px-4">
      <slot></slot>
    </li>
  </ul>
</template>

<script>
import Permissions from '@hellochef/shared-js/enums/Permissions';

export default {
  name: 'PaymentReconciliationTabs',
  props: {
    tab: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tabs: [
        {
          title: 'Summary',
          value: 'summary',
          permission: Permissions.PAYMENT_RECONCILIATIONS_SUMMARY_VIEW,
        },
        {
          title: 'Successful',
          value: 'successful',
          permission: Permissions.PAYMENT_RECONCILIATIONS_SUCCESSFUL_VIEW,
        },
        {
          title: 'Unsuccessful',
          value: 'unsuccessful',
          permission: Permissions.PAYMENT_RECONCILIATIONS_UNSUCCESSFUL_VIEW,
        },
      ],
    };
  },
  methods: {
    setTab(tab) {
      this.$emit('tab', tab);
    },
  },
};
</script>
