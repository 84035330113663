<template>
  <div class="table-responsive">
    <dimmer :active="loading">
      <table class="table table-outline table-vcenter card-table">
        <thead>
          <tr>
            <th v-for="item in columns" :key="item">
              {{item}}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.date">
            <td :data-test="'label-date-' + item.date">
              {{item.date}}
            </td>
            <td :data-test="'label-admin-transactions-sum-' + item.date">
              {{numeral(item.admin_transactions_sum).format('0,0[.]00')}}
            </td>
            <td :data-test="'label-admin-transactions-count-' + item.date">
              {{item.admin_transactions_count}}
            </td>
            <td :data-test="'label-checkout-transactions-sum-' + item.date">
              {{numeral(item.checkout_transactions_sum).format('0,0[.]00')}}
            </td>
            <td :data-test="'label-checkout-transactions-count-' + item.date">
              {{item.checkout_transactions_count}}
            </td>
          </tr>
          <tr v-if="items.length === 0">
            <td colspan="100%" class="text-center">
              There are no results matching your criteria.
            </td>
          </tr>
        </tbody>
      </table>
    </dimmer>
  </div>
</template>

<script>
export default {
  name: 'PaymentReconciliationTable',
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
