<template>
  <div class="container">
    <div v-show="successMessage" class="alert alert-icon alert-success alert-dismissible">
      <button
        type="button"
        class="close"
        @click="successMessage = ''"></button>
      <i class="fe fe-check mr-2" aria-hidden="true"></i>
      {{successMessage}}
    </div>
    <div v-show="errorMessage" class="alert alert-icon alert-danger alert-dismissible">
      <button
        type="button"
        class="close"
        @click="errorMessage = ''"></button>
      <i class="fe fe-alert-triangle mr-2" aria-hidden="true"></i>
      {{errorMessage}}
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label class="form-label">Date selection</label>
        <calendar-day-picker
          v-model="calendar"
          class="calendar-container"
          :range="true"
          @input="onCalendarInput"/>
        <small class="text-muted">Select dates to check the summary and reconciliations.</small>
      </div>
      <div v-if="can(uiPermissions.PAYMENT_RECONCILIATIONS_UPLOAD)" class="form-group col-md-6">
        <label class="form-label">Import checkout report</label>
        <div class="input-group">
          <div class="flex-1 position-relative">
            <input
              ref="fileInput"
              type="file"
              accept=".csv"
              class="form-control p-1"
              :disabled="uploading"
              @change="handleFileChange"
              @input="clearSelectedFile($event)"/>
            <i
              v-if="selectedFile"
              class="fe fe-x clearable"
              @click="clearSelectedFile($event, true)">
            </i>
          </div>
          <div class="input-group-append">
            <button
              class="btn btn-primary"
              :disabled="!selectedFile || uploading"
              @click="onUpload">
              <i v-if="uploading" class="fe fe-loader spin-icon"></i>
              Upload
            </button>
          </div>
        </div>
        <small class="text-muted">You can import checkout report without date selection.</small>
      </div>
    </div>
  </div>
</template>

<script>
import CalendarDayPicker from '@/components/CalendarDayPicker';
import {paymentReconciliations} from '@/services';
import moment from 'moment-timezone';
import uploader from '@/mixins/uploader';

export default {
  name: 'PaymentReconciliationFilter',
  components: {
    CalendarDayPicker,
  },
  mixins: [
    uploader,
  ],
  data() {
    return {
      calendar: {
        confirm: true,
        startDate: [],
      },
      selectedFile: null,
      uploading: false,
      successMessage: '',
      errorMessage: '',
    };
  },
  mounted() {
    // check if the query param has start_date and end_date and emit
    if (this.$route.query.start_date && this.$route.query.end_date) {
      this.calendar.startDate = [
        moment(this.$route.query.start_date),
        moment(this.$route.query.end_date),
      ];

      this.$emit('onDateSelected', this.calendar.startDate);
    }
  },
  methods: {
    onCalendarInput(weekObject) {
      this.$emit('onDateSelected', weekObject.startDate);
    },
    resetData() {
      this.errorMessage = '';
      this.successMessage = '';
    },
    async onUpload() {
      await this.handleUpload(() => paymentReconciliations.upload(this.selectedFile));
    },
  },
};
</script>

<style scoped>
.clearable {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 1;
}
</style>
