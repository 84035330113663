<template>
  <payment-reconciliation-table
    :loading="loading"
    :columns="success ? SUCCESSFUL_TABLE_COLUMNS : UNSUCCESSFUL_TABLE_COLUMNS"
    :items="items"/>
</template>

<script>
import {paymentReconciliations} from '@/services';
import {captureException} from '@sentry/vue';
import PaymentReconciliationTable from './PaymentReconciliationTable.vue';

const SUCCESSFUL_TABLE_COLUMNS = [
  'Order Date',
  'Matched Admin Value',
  'Matched Admin Count',
  'Matched Checkout Value',
  'Matched Checkout Count',
];

const UNSUCCESSFUL_TABLE_COLUMNS = [
  'Order Date',
  'Unmatched Admin Value',
  'Unmatched Admin Count',
  'Unmatched Checkout Value',
  'Unmatched Checkout Count',
];

export default {
  name: 'PaymentReconciliations',
  components: {
    PaymentReconciliationTable,
  },
  data() {
    return {
      loading: false,
      items: [],
      SUCCESSFUL_TABLE_COLUMNS,
      UNSUCCESSFUL_TABLE_COLUMNS,
    };
  },
  computed: {
    success() {
      return this.$route.query.t === 'successful';
    },
  },
  watch: {
    $route() {
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const {start_date, end_date} = this.$route.query;
        if (!start_date || !end_date) {
          this.items = [];
          return;
        }

        const {items} = await paymentReconciliations.fetch({start_date, end_date, success: this.success});
        this.items = items;
      }
      catch (error) {
        captureException(error);
      }
      finally {
        this.loading = false;
      }
    },
  },
};
</script>
